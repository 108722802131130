
  :deep().modal-dialog{
    max-width:600px!important ;
  }
  .body{
    width: 100%;
    padding-top: 63px;
    height: 353px;
    padding: 63px 24px 24px 24px;
    background-color: #ffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 15px 35px rgba(0,25,117,0.1), 0px 5px 15px rgba(0,25,117,0.5);
    .title{
      width: 450px;
      color: #2b2b2d;
      font-size: 16px;
      h3{
        width: 90%;
        font-weight: 600;
      }
      .content{
        width: 80%;
      }
    }
  }
.btn-save{
  margin-top: auto;
  max-width: 242px;
  width: 100%;
}
:deep().btn-link{
  margin-top: 10px;
  color: #0373a8;
  background-color: #ffff;
  border: none;
}
