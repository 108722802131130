
.btn-action{
  border-radius: 100px;
}
.template{
  width: 100%;
  height: 100%;
  overflow-wrap: break-word;
}
.ad-preview {
  min-height: 210px;
  max-width: 500px;
}
