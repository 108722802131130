
  .slider {
    height: 8px!important;
    .noUi-connect {
      background: #cccccc!important;
    }
  }
  .noUi-horizontal .noUi-handle, .noUi-vertical .noUi-handle{
    background:#012454!important;
  }
