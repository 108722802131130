
.input-textarea-shadow{
  box-shadow: inset 0 1px 3px rgba(1, 36, 84 , 0.3) !important;
}
.min-height-textarea{
  min-height: 178px!important;
}
.has-append-text {
  .input-group-append {
    .input-group-text {
      height: 30px!important;
    }
  }
  .form-control {
    height: 32px!important;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.8;
  }
  input{
    height: 100%;
    color: #2b2b2d;
    border: 1px solid #c6dcf5;
    border-radius: 5px;
    background-color: #ffffff ;
    box-shadow: inset 0 1px 3px 0 rgba(1,36,84,0.3);

    &:disabled {
      background-color: #e9ecef!important;
      opacity: 0.8!important;
    }
  
  }

  .input-group-append, .input-group-text{
    height: 100%;
    background-color: transparent;
    background-color: rgba(234, 237, 239, 0.3);
    font-weight: 500;
    color: #012454;
    font-size: 14px!important; 
    max-width: 100%;
  }
  .input-group-append{
    border: 1px solid #c6dcf5 ;
    border-radius: 5px ;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
