
.card-need-account {
  background-color: #8dfd36 !important;
  height: 115px;
  border-radius: 20px;
  padding: 0px 15px;
  .font-size-content {
    font-size: 14px;
  }
  .share-button-vi {
    font-size: 13px;
    padding: 0 10px!important;
  }
}
.blur {
  filter: blur(5px);
}
