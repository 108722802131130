
.font-size {
  font-size: 30px;
}
.subscription-btn{
  background-color: #8dfd36!important;
  color: #001975!important;
  width: 100%!important;
  display: flex;
}
