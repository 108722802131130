
.viFails{
  .title{
    font-size: 1.2rem;
  }
  .btn-close{
    max-height: 50px;
  }

}
