
.url-invalid{
  position: absolute;
  top: 4.25rem;
}

:deep().btn.btn-remove:disabled{
  background-color: transparent!important;
}

.btn-remove{
  border: none!important;
  margin-left: 5px!important;
  border-radius: 5px!important;
}
