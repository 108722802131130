
.widget-side{
    z-index: 1000;
    position: absolute;
    width: 290px;
    height: 135px;
    display: flex;
    right: 40px;
    transition: all .75s ease;
    bottom: 0;

    .widget-container{
        border-radius: 20px;
        background-image: linear-gradient(140deg, #8dfd36 0%, #369b8d 72%, #308bbd 100% );
        background-position: center center;
        background-size: cover;
        box-shadow: 0px 15px 35px rgba(0,25,117,0.1), 0px 5px 15px rgba(1,36,84,0.3);
        display: flex;
        align-items: center;
        padding:10px ;
        .widget-arrow{
            border-right: 1px solid #0373a8;
            height: 80%;
            margin-right: 5px;
            cursor: pointer;
            width: 20px;
            display: flex;
            align-items: center;
            .img-vector{
                height: 13px;
                width: 14px;
            }

        }
        .widget-text{
            width: 248px ;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h1{
                font-size: 15px;
                padding: 5px;
                margin: 0px;
            }
            p{
                font-size: 13px;
                padding: 5px;
                margin: 0px;
                color: #012454;
                font-weight: 500;
            }
            .btn-widget{
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 210px;
                height: 33px;
                .img-vector{
                    margin-right: 5px;
                    height: 14px;
                    width: 14px;
                }
            }
    
        }
    }
    .widget-vector{
        .img-vector{
            margin-top: 80px;
            margin-left: -0.5px;
        }
    }
    &.is-hide{
        right: 0;
        width: 45px;
        

        .widget-container{
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
        }
        .widget-text, .widget-vector{
            display: none;
        }
    }
    
}

