
.icons-ads-height {
  max-height: 60px;

  &.facebook-icon-ads {
    max-height: 80px !important;
  }
}

.ad-preview-meta {
  aspect-ratio: 210px/500px;
}

.ad-preview-google {
  aspect-ratio: 210px/500px;
}

.label-light {
  color: #adb5bd;
}

.input-group .input-stevie {
  border-radius: 5px !important;

  &.no-rounded-borders {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  &:disabled {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}

.input-group {
  .input-group-append.no-btn {
    background-color: #c6dcf5;
    min-width: 43px;
    border: 1px solid #c6dcf5 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 5px !important;
    border-top-right-radius: 5px !important;
    color: #adb5bd;
  }
}

.containerSaveButton {
  height: 180px;
}

.keyword-input {
  height: auto !important;

  .keyword-input__keyword {
    height: 25px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-top: 6px;
    line-height: 25px;
    padding: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }

  .keyword-input__keyword>span {
    cursor: pointer;
    opacity: 0.75;
  }

  .keyword-input__text {
    border: none;
    outline: none;
    line-height: 34px;
    background: none;
    width: 200px;
    max-width: 100%;
  }

  &.disabled {
    background-color: #EDF0F2 !important;

    .keyword-input__keyword {
      background-color: #FFFFFF;
    }
  }
}

.regionalCheckboxText {
  text-transform: none;
}

.sliderText {
  font-size: 13px;
}

.sliderInvested {
  width: -webkit-fill-available;

  .noUi-connect {
    background: #cccccc !important;

    .noUi-horizontal .noUi-handle,
    .noUi-vertical .noUi-handle {
      background: #012454 !important;

    }
  }
}

.icons-distribution {
  height: 20px;
  width: 20px;
}

:deep().el-switch__label.is-active {
  color: #5E72E4 !important;
}

:deep().min-height-textarea {
  height: 91px;
}

:deep() .textarea-block {
  height: 91px !important;
}

.small-text {
  font-size: 11px;
  opacity: 0.8;
  display: flex;
  align-items: center;
}

.word-break {
  word-break: break-word;
}

.info-errors {
  background-color: #FFF3CD;
  color: #997404 !important;

  .card-body {
    padding: 0.75rem !important;
  }

  h3,
  .icon-title {
    color: #A16207 !important;
    margin-bottom: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.custom-card {
  background: #ffffff;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  padding: 20px 21px;
  border-radius: 20px;
}

.segment {
  margin: 0 -21px;
  padding: 22px 31px 21px;

  &.footer {
    padding-top: 29px;
    margin-bottom: -20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &.highlight-segment {
    background-color: #E7F6FD;
  }
}

:deep()input[type="date"] {
  appearance: none !important;
  padding-left: 33px !important;
  background: url('/img/icons/stevie/calendar-alt.svg') no-repeat left;
  background-size: 13px;
  background-position: 9px 8px;
}

:deep()input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.google-description {
  height: 77px !important;
  padding: 10px !important;
}

.btn-add-fields {
  background-color: transparent !important;
  color: #0373a8;
  font-size: 13px;
  border: none;
  box-shadow: none;
  padding: 0px;
  display: flex;
  align-items: center;

  &:hover,
  &:active,
  &:focus {
    color: #10305D !important;
    box-shadow: none;
  }

  .icon {
    height: 13px;
    width: 13px;
  }
}

.btn-description {
  border: none !important;
  margin-left: 5px !important;
  border-radius: 5px !important;
}

.uploader-campaigns {
  height: 150px;

  &.square-image {
    margin: 0 auto;
    aspect-ratio: 1 / 1;
  }
}

label:has(.sublabel) {
  display: flex;
  flex-wrap: wrap;

  .label-text {
    padding-right: 5px;
  }

  .sublabel {
    text-transform: none;
    font-weight: 500;
  }
}

.magic-wand-styles {
  width: 1.2rem;
  height: 1.2rem;
  margin-bottom: 3px;
}

.icon-delete-video {
  position: absolute;
  top: 10px;
  right: 10px;
}

img {
  &.high-brightness {
    filter: brightness(5);
  }
}

.magicWand {
  bottom: 0.3rem;
  right: 0rem;
  z-index: 9
}

.hide-scrollbar {
  overflow: hidden;
}

@media (min-width: 768px) {
  .container-end-on {
    margin-top: 1.85rem !important;
  }
}

@media (min-width: 1440px) {
  .containerSaveButton {
    height: 95px;
  }
}
