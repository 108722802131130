
.subcription-text {
    font-weight: 300;
    color: rgba(0, 0, 0, 0.65);
}
.text-landing-stevie {
    font-size: 16px;
}
.card-shadow-landing{
    margin-bottom: 25px;
    /deep/ .card-body{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
.subscription-info{
    .color-muted{
        color: #8898aa;
    }
}
.min-h-text{
    min-height: 80px;
}
.button-height-33{
    max-width: 225px;
}
.alert-danger{
    color: #f75676;
    border-color: #f75676;
    background-color: transparent;
}
.alert-success{
    color: #4fd69c;
    border-color: #4fd69c;
    background-color: transparent;
}
