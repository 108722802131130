
.modal-height {
  min-height: 430px;
}
.size-bnt-rrss{
  max-width: 170px;
  .btn-image {
    height: 170px;
    width: 170px;
    max-height: 170px;
    max-width: 170px;
    .none-image {
      color: #7386a1;
    }
    img,
    .none-image {
      height: 120px;
      width: 120px;
      border: 2px solid #91abbf;
      border-radius: 3px;
    }
    .space-none-btn{
      height: 37px;
    }
  }
  .active-btn {
    box-shadow: none;
    background-color: rgba(90,111,231,0.1);
  }
}
.logo-img{
  max-height: 120px;
}
@media (max-width: 576px) {

  .btn-margin{
    margin-left: 0px;
  }
}
