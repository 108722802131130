// HeyStevie Colors

$stevie-white:            #ffffff;
$stevie-athens-gray:      #f8f9fa;
$stevie-concrete:         #f2f2f2;
$stevie-reef:             #d9fda5;
$stevie-athens-gray2:     #e2e6ea;
$stevie-alto:             #dadada;
$stevie-tropical-blue:    #c6dcf5;
$stevie-green-yellow:     #b4fb4d;
$stevie-green-yellow2:    #8dfd36;
$stevie-ghost:            #c1c4cd;
$stevie-malibu:           #86d3f7;
$stevie-tan-hine:         #f98f4c;
$stevie-manatee:          #8c8c93;
$stevie-gray:             #8c8c8c;
$stevie-boston-blue:      #308db3;
$stevie-radical-red:      #f5365c;
$stevie-dove-gray:        #666666;
$stevie-azure-radiance:   #007bff;
$stevie-allports:         #0373a8;
$stevie-shark:            #2b2b2d;
$stevie-prussian-blue:    #012454;
$stevie-resolution-blue:  #001975;
$stevie-dark-blue:        #0404c7;
$stevie-woodsmoke:        #16181b;
$stevie-black:            #000000;
$stevie-foam:             #def3fc;

// HeyStevie Gradients

$stevie-gradient-malibu: linear-gradient(90deg, $stevie-malibu 0%, rgba(134, 211, 247, 0.3) 100% );
$stevie-gradient-prussian: linear-gradient(90deg, $stevie-prussian-blue 0%, $stevie-prussian-blue 40%, $stevie-allports 100%);
$stevie-gradient-gauge-a: linear-gradient(90deg, #f6365b 43%, #ffb700 55%, #f6fa00 96%, #8dfd36 100% );
$stevie-gradient-gauge-b: linear-gradient(-60deg, transparent 20%, #fcd200 34%, transparent 50% );
$stevie-gradient-gauge-c: linear-gradient(-30deg, #8dfd36 0%, rgba(141, 253, 54, 0) 40% );

$stevie-colors: () !default;
$stevie-colors: map-merge((
  "stevie-white":           $stevie-white,
  "stevie-athens-gray":     $stevie-athens-gray,
  "stevie-concrete":        $stevie-concrete,
  "stevie-reef":            $stevie-reef,
  "stevie-athens-gray2":    $stevie-athens-gray2,
  "stevie-alto":            $stevie-alto,
  "stevie-tropical-blue":   $stevie-tropical-blue,
  "stevie-green-yellow":    $stevie-green-yellow,
  "stevie-green-yellow2":   $stevie-green-yellow2,
  "stevie-ghost":           $stevie-ghost,
  "stevie-malibu":          $stevie-malibu,
  "stevie-tan-hine":        $stevie-tan-hine,
  "stevie-manatee":         $stevie-manatee,
  "stevie-gray":            $stevie-gray,
  "stevie-boston-blue":     $stevie-boston-blue,
  "stevie-radical-red":     $stevie-radical-red,
  "stevie-dove-gray":       $stevie-dove-gray,
  "stevie-azure-radiance":  $stevie-azure-radiance,
  "stevie-allports":        $stevie-allports,
  "stevie-shark":           $stevie-shark,
  "stevie-prussian-blue":   $stevie-prussian-blue,
  "stevie-resolution-blue": $stevie-resolution-blue,
  "stevie-dark-blue":       $stevie-dark-blue,
  "stevie-woodsmoke":       $stevie-woodsmoke,
  "stevie-black":           $stevie-black,
), $stevie-colors);

// HeyStevie margins
$dashboard-margin-left:    22px !important;
$dashboard-margin-right:    22px !default;

$dashboard-margin-left-sm:    22px !default;
$dashboard-margin-right-sm:    22px !default;

// HeyStevie paddings
$sidebar-padding-x: 36px !default;
