
.el-table_1_column_4 th {
  text-align: right;
}
:deep() .el-table__body-wrapper{
  min-height: 350px!important;
}
.min-table{
  min-height: 450px;
  max-width: 99%!important;
}
.dropdown-item{
  height: 33px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover{
    color: #16181b!important;
    background-color: #f6f9fc!important;
  }
}
.icon-info-error{
  color: #FFC107;
}
