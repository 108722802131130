
.icons-ads-height {
  max-height: 60px;
  &.facebook-icon-ads {
    max-height: 80px !important;
  }
}
.input-group .input-stevie {
  border-radius: 5px !important;
  &.no-rounded-borders {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  &:disabled {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}
.input-group {
  .input-group-append.no-btn {
    background-color: #c6dcf5;
    min-width: 43px;
    border: 1px solid #c6dcf5 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 5px !important;
    border-top-right-radius: 5px !important;
    color: #adb5bd;
  }
}
.keyword-input {
  height: auto !important;

  .keyword-input__keyword {
    height: 25px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-top: 6px;
    line-height: 25px;
    padding: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  .keyword-input__keyword > span {
    cursor: pointer;
    opacity: 0.75;
  }

  .keyword-input__text {
    border: none;
    outline: none;
    line-height: 34px;
    background: none;
    width: 200px;
    max-width: 100%;
  }
  &.disabled {
    background-color: #edf0f2 !important;
    .keyword-input__keyword {
      background-color: #ffffff;
    }
  }
}
.small-text {
  font-size: 11px;
  opacity: 0.8;
  display: flex;
  align-items: center;
}
.word-break {
  word-break: break-word;
}
:deep().custom-radio .custom-control-label{
  font-size: 13px;
  color: #8c8c93;
  text-transform: none!important;
  font-weight: 400;
}

.custom-card {
  background: #ffffff;
  box-shadow: 0 0 2rem 0 rgba(136,152,170,0.15);
  padding: 20px 21px;
  border-radius: 20px;
}
.segment {
  margin: 0 -21px;
  padding: 22px 31px 21px;

  &.footer {
    padding-top: 29px;
    margin-bottom: -20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &.highlight-segment {
    background-color: #E7F6FD;
  }
}


.sliderText {
  font-size: 13px;
}
.sliderInvested {
  width: -webkit-fill-available;
  .noUi-connect {
    background: #cccccc!important;

    .noUi-horizontal .noUi-handle, .noUi-vertical .noUi-handle {
      background: #012454!important;

    }
  }
}
.icons-distribution {
  height: 20px;
  width: 20px;
}
.regionalCheckboxText {
  text-transform: none;
}
:deep().min-height-textarea{
  height: 91px;
}
:deep() .textarea-block{
  height: 91px!important;
}
//Google fields
.btn-description{
  border: none!important;
  margin-left: 5px!important;
  border-radius: 5px!important;
}
.google-description{
  height: 77px !important;
}
.btn-add-fields{
  background-color: transparent!important;
  color: #0373a8;
  font-size: 13px;
  border: none;
  box-shadow: none;
  padding: 0px;
  display: flex;
  align-items: center;
  &:hover, &:active, &:focus{
    color: #10305D!important;
    box-shadow: none;
  }
  .icon{
    height: 13px;
    width: 13px;
  }
}
//Styles of footer form
.containerSaveButton {
  height: 95px;
}

//Styles of input image
.uploader-campaigns{
  height: 107px;

  &.square-image {
    height: 300px!important;
    :deep().dz-default {
      height: 300px!important;
    }
  }
}
label:has(.sublabel){
  display: flex;
  flex-wrap: wrap;
  .label-text{
    padding-right: 5px;
  }
  .sublabel{
    text-transform: none;
    font-weight: 500;
  }
}

