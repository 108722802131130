
    .btn-recharge {
        height: 100px;
        max-height: 150px;
        border: 1px solid lightgray;
        width: 100%;
        &.active-btn {
          box-shadow: none;
          background-color: rgba(90, 111, 231, 0.1);
        }
    }
    .modal-recharge{
        min-height: 300px;
    }
    .wallet-total{
      font-size:1.2rem;
      font-weight: 600;
    }
    .width-column{
      max-width: 70px!important;

    }
    .min-table{
      min-height: 450px;
      max-width: 99%;
    }

    button.btn-link{
      border: none;
      background: transparent;
      padding-left: 10px;
      text-decoration:underline;
      cursor: pointer;
      &:disabled{
        text-decoration: none;
      }
    }

    @media (min-width: 760px){
      /deep/ .modal-dialog{
          max-width: 50%!important;
      }

    }
