
.cursor-pointer {
  cursor: pointer;
}
.error-message-top {
  top: 2.2rem;
}
:deep()input[id="name"], input[id="last_name"], input[id="company_name"]{
  appearance: none!important;
  padding-left: 33px!important;
  background: url('/img/icons/stevie/user-solid.svg') no-repeat left;
  background-size: 13px;
  background-position: 10px 10px;
}
:deep()input[id="email"]{
  appearance: none!important;
  padding-left: 33px!important;
  background: url('/img/icons/stevie/envelope-solid.svg') no-repeat left;
  background-size: 13px;
  background-position: 10px 10px;
}
:deep()input[type="password"]{
  appearance: none!important;
  padding-left: 33px!important;
  background: url('/img/icons/stevie/unlock-keyhole-solid.svg') no-repeat left;
  background-size: 13px;
  background-position: 10px 10px;
}
