
.magic-wand-styles {
  width: 1.3rem;
  height: 1.3rem;
  margin-bottom: 3px;
}
.magic-wand-styles:hover:not(.disabled, .not-hover-active),
.magic-wand-styles-text:hover:not(.disabled, .not-hover-active) {
  border: 1px solid #71ad47;
  border-radius: 0.3rem;
}
.magic-wand-styles:active:not(.disabled, .not-hover-active),
.magic-wand-styles-text:active:not(.disabled, .not-hover-active) {
  border: 1px solid #71ad47;
  background: #c2dcb0;
  border-radius: 0.3rem;
}

img {
  &.disabled {
    cursor: not-allowed;
    filter: grayscale(1);
  }
}
