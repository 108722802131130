
.required-label {
  font-size: 15px;
  color: red;
}
.magic-wand-modal {
    scrollbar-width: none;  /* Firefox */
}
.magic-wand-modal::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}
.magic-wand-modal {
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
}
.input-stevie {
  &.keyword-input {
    height: 100%!important;
  }
}
.keyword-input{
  height: 100%!important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;

  .keyword-input__keyword {
    height: 25px;
    background-color: #eee;
    line-height: 25px;
    padding: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  .keyword-input__keyword > span {
    cursor: pointer;
    opacity: 0.75;
  }

  .keyword-input__text {
    border: none;
    outline: none;
    line-height: 34px;
    background: none;
    display: flex;
    flex-grow: 1;
    max-width: 100%;
  }
  &.disabled{
    background-color: #EDF0F2!important;
    .keyword-input__keyword {
      background-color: #FFFFFF;
    }
  }
}
@media (min-width: 780px) {
  .modal-dialog {
    max-width: 75% !important;
  }
}
