// Helpers

.img-contain{
  object-fit: contain;
}
.img-cover{
  object-fit: cover;
}
.disabled-click{
  pointer-events: none;
}
.h-fit{
  height: fit-content;
}
// Ads
.ellipsis-shadow {
  filter: drop-shadow(0px 1px 2px rgba(3,115,168,0.5));
}
.ellipsis-shadow:hover {
  filter: none;
}
.el-dropdown__popper {
  border-color: $stevie-malibu !important;
}
.rotateArrow {
  transform: rotate(90deg);
}
.media-ad {
  opacity: 1;
  object-fit: cover;
  border-radius: 5px;
}
.bin-ad {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}
.delete-ad-media .bin-ad {
  opacity: 1;
}
.size-media-ads {
 height: 300px;
}
.custom-status {
  background-image: linear-gradient(-60deg, #f44611 40%, #3b83bd 55%, #3b83bd 100%) !important;
}
.ad-form, .landing-form {
  .dz-message {
    background-color: transparent;
    border: 2px dashed #86d3f7 !important;
    border-radius: 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px !important;
    line-height: 1.5rem;
    .display-image{
      display: flex;
      .text-hover{
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.6);
        color: #8C8C93;
        width: 100%;
        height: 100%;
      }
      &:hover .text-hover {
        display: flex;
        color: #ffffff;
      }
      img {
        max-width: fit-content;
      }
    }
  }
  .dz-button {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    color: #012454 !important;
  }
}

// Backgrounds
.background-stevie {
  background: linear-gradient(
      -140deg,
      rgba(141, 253, 54, 1) 0%,
      rgba(54, 155, 141, 1) 50%,
      rgba(48, 139, 189, 1) 100%
  );
}
.background-dashboard {
  background: $stevie-allports;
}
.background-dashboard-gradient {
  background: linear-gradient(160deg, $stevie-allports 0%, $stevie-prussian-blue 50%);
}
.bg-blue-stevie {
  background-color: $stevie-prussian-blue !important;
}
.bg-green-yellow {
  background-color: $stevie-green-yellow2 !important;
}
.bg-malibu {
  background-color: $stevie-malibu !important;
}
.bg-malibu-stevie {
  background-color: rgba(134, 211, 247, 0.2);
}
.bg-before-landing-stevie:before {
  content:'';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 127px;
  right: 127px;
  background: linear-gradient(180deg, rgba(3,115,168,0) 40%, rgba(3,115,168,1) 100%);
}
.bg-before-img:before {
  content:'';
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, $stevie-prussian-blue 0%, rgba(1, 36, 84, 0) 100% );
  border-bottom-left-radius: 20px;
}
.bg-footer-landing {
  background-color: $stevie-prussian-blue;
}
.bg-gradient-landing-contact {
  width: 191px;
  height: 191px;
  background: linear-gradient(-40deg, #0373a8 4%, #369b8d 51%, #8dfd36 85% );
  border-radius: 100px;
}
.tabs-stevie{
  color: $stevie-allports !important;
  .nav-pills .nav-link{
  &.disabled{
    color: rgba(0, 0, 0, 0.35)!important;
  }
}
}
.tabs-stevie.nav-link.active,
.show > .nav-link {
  color: $stevie-shark !important;
  background-color: rgba(3, 115, 168, 0.2) !important;
  box-shadow: inset 0 1px 3px rgba(3,115,168,0.2) !important;
}
.bg-athens-gray {
  background-color: $stevie-athens-gray2;
}

// Borders
.border-left-none {
  border-left: none !important;
}
.border-right-none {
  border-right: none !important;
}
.border-input-form-vi {
  border: 1px solid #dee2e6;
}
.border-radius-none {
  border-radius: 0 !important;
}
.border-radius-stevie {
  border-radius: 20px !important;
}
.border-radius-left-stevie {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}
.border-radius-right-stevie {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}
.border-radius-bottom-stevie {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}
.border-radius-top-stevie {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.border-radius-left-30 {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}
.border-radius-right-30 {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
.border-radius-top-none {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

// Buttons
.btn{
  font-weight: 500!important;
}
.button-margin-right-vi {
 margin-right: 10px;
}
.button-width-form-vi {
  width: 131px;
}
.trial-button-vi {
  width: 225px !important;
  padding: 7px !important;
  font-size: 14px !important;
}
.share-button-db {
  width: 96px !important;
  padding: 7px !important;
  font-size: 14px !important;
  background-color: $stevie-malibu !important;
  color: $stevie-prussian-blue !important;
}
.profile-button {
  width: 100px;
  height: 33px;
}
.save-button {
  width: 150px;
  height: 38px;
  padding: 7px;
  background-color: $stevie-prussian-blue;
  color: $stevie-white;
}
.start-campaign-button {
  width: 242px;
  height: 38px;
  padding: 7px;
  font-size: 14px;
  background-color: $stevie-prussian-blue;
  color: $stevie-white;
}
.back-button {
  width: 180px;
  height: 32px;
  padding: 0px 10px;
  border: 1px solid #c6dcf5 !important;
  border-radius: 5px;
  background-color: $stevie-white !important;
  font-family: Montserrat;
  font-size: 13px;
  color: $stevie-prussian-blue !important;
  text-decoration: none solid rgb(1, 36, 84);
  box-shadow: 0px 1px 5px rgba(3,115,168,0.2) !important;
}
.login-facebook {
  background-color: #1877F2 !important;
  img {
    width: 26px;
    filter: brightness(0) saturate(100%) invert(95%) sepia(5%) saturate(18%) hue-rotate(140deg) brightness(104%) contrast(107%);
  }
}
.unlink-button {
  width: 120px;
}
.cancel-button {
  background-color: $stevie-white !important;
  color: $stevie-woodsmoke !important;
}

.soft-green-button{
  background-color: #98DF59!important;
  border-color: #98DF59!important;
}

// Cards
.bg-white-50 {
  background: rgba(255, 255, 255, 0.5) !important;
}
.card {
  .margin-left-title {
    margin-top: 16px;
    margin-left: 16px;
  }
  .margin-left-bottom-title {
    margin-bottom: 16px;
    margin-left: 16px;
  }
  .margin-right-title {
    margin-top: 16px;
    margin-right: 16px;
  }
  .padding-x {
    padding: 0 16px;
  }
  .card-badge-campaign {
    width: 55px;
    height: 18px;
    padding: 4px 5px;
    border-radius: 4px;
    background-color: rgba(245, 54, 92, 0.3);
    font-size: 12px;
    color: $stevie-black;
  }
  .card-badge-clicks {
    width: 44px;
    height: 15px;
    padding: 2px 5px;
    border-radius: 4px;
    background-color: rgba(141, 253, 54, 0.6);
    font-size: 12px;
    color: $stevie-shark;
  }
  .card-badge-impressions {
    width: 44px;
    height: 15px;
    padding: 2px 5px;
    border-radius: 4px;
    background-color: rgba(245, 54, 92, 0.3);
    font-size: 12px;
    color: $stevie-shark;
  }
  .card-title-stevie-vi {
    font-weight: normal;
  }
  .card-text-stevie-vi {
    font-weight: bold;
  }
  .font-size-content {
    font-size: 16px;
    color: $stevie-prussian-blue;
  }
  .font-social-channel {
    font-size: 12px;
    color: $stevie-prussian-blue;
  }
  .circle-social {
    width: 12px;
    height: 12px;
    border-radius: 10px;
  }
  .google {
    background-color: $stevie-resolution-blue;
  }
  .facebook {
    background-color: rgba(3, 115, 168, 0.5);
  }
  .instagram {
    background-color: $stevie-malibu;
  }
  .twitter {
    background-color: rgba(134, 211, 247, 0.5);
  }
}
.card-form-vi {
  height: auto;
  background: linear-gradient(-140deg, #8dfd36 0%, #00c4aa 50%, #0373a8 100% )
}
.card-vi {
  height: 280px;
}
.card-height-small {
  height: 120px;
}
.card-click-rates {
  height: 420px;
}
.card-text {
  font-size: 16px;
  color: $stevie-shark;
}
.card-index {
  font-size: 50px;
  font-weight: 900;
  background: $stevie-gradient-prussian;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  .percent-size {
    font-size: 16px;
    background: $stevie-prussian-blue;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.center-card-visibility {
  left: 50%;
  transform: translateX(-50%);
}
.font-size-title-form {
  font-size: 1.3rem;
}
.icon-card {
  width: 15px;
}
.bg-company-size {
  background-color: $stevie-foam !important;
}
.font-company-size-bold{
  font-weight: bold;
}
.text-company-size {
  color: $stevie-allports;
  font-size: 16px;
}

// Cursors
.cursor-pointer {
  cursor: pointer;
}

// Dashboard
.dashboard-margin {
  margin-left: $dashboard-margin-left;
  margin-right: $dashboard-margin-right !important;
}
.g-sidenav-pinned .dashboard-margin {
  margin-left: $dashboard-margin-left-sm;
}

// Forms
.invalid-feedback {
  position: absolute;
}
.invalid-feedback.mt-2 {
  margin-top: 0.25rem !important;
}
.subtitle-form{
  color: #8c8c93;
  font-weight: 600;
  font-size: 16px;
}

// Gauge
.container-gauge{
  width:472px;
  height:240px;
  left: 50%;
  overflow: hidden;
  text-align: center;
  transform: translate(-50%, -50%);
}
.gauge-a{
  z-index: 1;
  position: absolute;
  background: $stevie-gradient-gauge-a;
  width: 400px;
  height: 200px;
  bottom: 0;
  left: 36px;
  border-radius:250px 250px 0 0 ;
}
.gauge-b{
  z-index: 2;
  position: absolute;
  background: $stevie-gradient-gauge-b;
  width: 400px;
  height: 200px;
  bottom: 0;
  left: 36px;
  border-radius:250px 250px 0 0 ;
}
.gauge-c{
  z-index: 3;
  position: absolute;
  background: $stevie-gradient-gauge-c;
  width: 400px;
  height: 200px;
  bottom: 0;
  left: 36px;
  border-radius:250px 250px 0 0 ;
}
.gauge-d{
  z-index: 3;
  position: absolute;
  background-color: $stevie-white;
  width: 200px;
  height: 100px;
  top: 140px;
  left: 36px;
  margin-left: 100px;
  margin-right: auto;
  border-radius:250px 250px 0 0 ;
}
.gauge-data{
  z-index: 4;
  color: $stevie-white;
  position: absolute;
  width: 472px;
  height: 260px;
  top: 70%;
  transition: all 1s ease-out;
}
.gauge-line {
  z-index: 4;
  position: absolute;
  width: 236px;
  top: 182px;
  transform-origin: left center;
  transform: rotate(-180deg);
}

// Heights
.button-height-33 {
  height: 33px;
}
.input-button-height-vi {
  height: 52px !important;
}
.navbar-height {
  height: 138px;
}
.navbar-height-vi {
  height: 60px;
}
.navbar-items-height {
  height: 70px;
}
.img-height {
  margin-top: 60px;
  height: 400px;
}
.stevie-title-height-100 {
  min-height: 100px;
}
.stevie-text-height-250 {
  min-height: 250px;
}
.text-card-height {
  min-height: 150px;
}

// Inputs
.style-arrow-input {
  transform: scaleX(-1) rotate(10deg);
}
.input-form-vi:focus {
  border-color: $stevie-white !important;
}
.input-form-vi::placeholder {
  color: $stevie-allports !important;
}
textarea{
  resize: none!important;
}
.input-stevie {
  height: 34px !important;
  padding: 5px 10px !important;
  border: 1px solid $stevie-tropical-blue !important;
  border-radius: 5px !important;
  background-color: $stevie-white !important;
  font-size: 13px !important;
  color: $stevie-shark !important;
  text-decoration: none solid $stevie-shark !important;
  box-shadow: inset 0 1px 3px rgba(1,36,84,0.3) !important;
  &:disabled {
    background-color: #e9ecef!important;
    opacity: 0.8!important;
  }
  &.disabled{
    background-color: #EDF0F2!important;
    .keyword-input__keyword {
      background-color: #FFFFFF;
    }
  }
}
.input-stevie:focus, .textarea-stevie:focus {
  outline: none;
  border: none;
}
.input-group{
  .input-stevie{
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
  }
}
.btn-input-stevie{
  height: 34px !important;
  padding: 0px 10px !important;
  font-size: 13px !important;
  border-radius: 5px !important;
  border-top-right-radius: 5px!important;
  border-bottom-right-radius: 5px!important;
  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
  border: 1px solid $stevie-tropical-blue !important;
}
.textarea-stevie {
  padding: 5px 10px !important;
  border: 1px solid $stevie-tropical-blue !important;
  border-radius: 5px !important;
  background-color: $stevie-white !important;
  font-size: 13px !important;
  color: $stevie-shark !important;
  text-decoration: none solid $stevie-shark !important;
  box-shadow: inset 0 1px 3px rgba(1,36,84,0.3) !important;
  &:disabled {
    background-color: #e9ecef!important;
    opacity: 0.8!important;
  }
}
.input-placeholder-stevie {
  height: 15px;
  font-size: 12px;
  color: $stevie-shark;
  text-decoration: none solid $stevie-shark;
}
label{
  font-weight: 600;
  color: #012454;
  font-size: 10px;
  text-transform: uppercase;
}

select.form-control.input-stevie, select.form-control.input-stevie option{
  appearance: none;
  box-shadow: none!important;
  font-weight: 600;
  color: #012454!important;

}
select.form-control.input-stevie{

    padding-right: 33px!important;
    background: url('/img/icons/stevie/arrow-down.svg') no-repeat left;
    background-size: 15px;
    background-position: calc(100% - 10px) 12px;

}
.input-font-size{
  width: 68px;
}

.autocomplete-stevie {
  .el-input {
    .el-input__inner{
      height: 34px !important;
      padding: 0px 10px !important;
      border: 1px solid $stevie-tropical-blue !important;
      border-radius: 5px !important;
      background-color: $stevie-white !important;
      font-size: 13px !important;
      line-height: 34px!important;
      color: $stevie-shark !important;
      text-decoration: none solid $stevie-shark !important;
      box-shadow: inset 0 1px 3px rgba(1,36,84,0.3) !important;
      &:disabled {
        background-color: #e9ecef!important;
        opacity: 0.8!important;
      }
    }
  }
}

.el-autocomplete-suggestion {
  width: auto!important;
  min-width: 300px;
}

// Landing page
.landing-page-padding-left {
  padding-left: $dashboard-margin-left !important;
}
.text-landing-stevie {
  font-size: 18px;
  color: $stevie-shark;
}
.title-size-landing-stevie {
  font-size: 16px;
}
.font-size-landing-stevie {
  font-size: 12px;
}
.mt-landing-stevie {
  margin-top: -180px;
}
.icon-size-social-landing {
  width: 50px;
}
.icon-size-instagram-landing {
  width: 70px;
}
.card-height-landing {
  min-height: 350px;
}
.card-shandow-landing {
  box-shadow: 0 5px 15px rgba(134,211,247,0.3) !important;
}
.box-shadow-contact {
  box-shadow: 0 3px 5px rgba(3,115,168,0.3) !important;
}

// Line height
.stevie-line-height-13 {
  line-height: 1.3;
}

// Modals
.modal-company-size {
  max-width: 1046px !important;
  padding: 24px;
}
.shadow-company-size {
  box-shadow: 0 5px 15px rgba(134,211,247,0.5) !important;
}

// Navbar
.padding-navbar {
  padding-left: 22px;
  padding-right: 22px;
}
.bg-navbar-items {
  background: rgba(255, 255, 255, 0.9);
}
.logo-shadow {
  filter:drop-shadow(3px 3px 4px $stevie-white)
}
.logo-size {
  width: 136px;
  height: 74px;
}
.logo-size-vi {
  width: 200px;
  height: 109px;
}
.padding-navbar-icons {
  padding-right: 38px;
}
.navbar-toggler-icon-prussian {
  filter: brightness(0) saturate(100%) invert(9%) sepia(44%) saturate(4213%) hue-rotate(204deg) brightness(98%) contrast(100%);
}
.navbar-toggler-icon-white {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(233deg) brightness(104%) contrast(104%);
}
.deactivate{
  color: $stevie-prussian-blue !important;
}

// Profile data
.img-profile-data {
  width: 82px;
  height: 82px;
  border-radius: 100px;
}
.online-shape-profile {
  margin: 62px 5px 0 65px;
}
.user-profile {
  .dz-message {
    border: 2px dashed #86d3f7 !important;
    border-radius: 5px !important;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .dz-button {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    color: #012454 !important;
  }
}
.crop-image {
  position: relative;
  .crop-container {
    margin-top: 10px;
    left: 50%;
    top: -10px;
    .crop-button {
      min-width: 220px;
      transform: translateX(-100%);
      padding: 5px 20px;
      transition: background 0.5s;
      &:hover {
        background: rgba($stevie-allports, 0.9) !important;
      }
  }
  }
}

// Rotates transform
.stevie-rotate-180 {
  transform: rotate(180deg);
}

// Scroll
  .scroll-smooth {
    scroll-behavior: smooth;
  }

// SideBar
.stevie-sidebar {
  margin-left: 22px;
  margin-bottom: 30px;
  .light-badge {
    margin: 18px 18px 0 0;
    width: 69px;
    height: 18px;
    padding: 4px 5px;
    border-radius: 4px;
    background-color: $stevie-malibu;
    font-size: 12px;
    color: $stevie-allports;
  }
  .navbar-inner {
    margin-top: 35px;
  }
  .btn-logout {
    border-color: transparent;
    color: $stevie-allports;
  }
  .btn-logout:hover {
    border-color: transparent;
    background-color: transparent;
    color: $stevie-allports;
    box-shadow: none;
  }
  .btn-logout:active {
    border-color: transparent !important;
    background-color: transparent !important;
    color: $stevie-allports !important;
    box-shadow: none !important;
  }
  .icon-size {
    width: 14px;
  }
  .text-color {
    color: $stevie-prussian-blue;
  }
}
.img-profile {
  width: 82px;
  height: 82px;
  border-radius: 100px;
}
.username {
  margin-top: 10px;
  h3 {
    font-size: 16px;
  }
  span {
    font-size: 12px;
  }
}

// Styles stevie components
.online-shape {
  width: 12px;
  height: 12px;
  border: 2px solid $stevie-white;
  border-radius: 10px;
  background-color: $stevie-green-yellow2;
  background-size: cover;
  box-shadow: 0 1px 10px $stevie-allports;
}
.online-shape-navbar {
  margin: -4px 0 0 18px;
}
.online-shape-sidebar {
  margin: 6px 5px 0 65px;
}

// Styles icons svg
.styles-loading-shape {
  width: 14px;
  height: 13px;
  fill: #86d3f7;
  transform: rotate(360deg);
}
.center-position-absolute {
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.center-position-absolute-left {
  left: 60px;
  transform: translateX(-50%) translateY(-50%);
}

// Text colors
.text-woodsmoke {
  color: $stevie-woodsmoke;
}
.text-malibu {
  color: $stevie-malibu;
}
.text-prussian-blue {
  color: $stevie-prussian-blue !important;
}
.text-dark {
  color: $stevie-shark;
}
.text-allports {
  color: $stevie-allports;
}
//To make the tooltip text appear multi-line
.el-popper{
  white-space: pre-line!important;
  max-width: 400px;
}

// Media queries
@media (max-width: 576px) {
  // Borders
  .border-radius-xs-left-30 {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
  }

  // Buttons
  .font-size-form-vi {
    font-size: 8px !important;
  }
  .save-button {
    width: 120px;
  }

  // Cards
  .card-text {
    font-size: 8px;
  }
  .icon-card {
    width: 10px;
  }
  .card-vi {
    height: 400px;
  }

  // Height
  .stevie-text-height-250 {
    min-height: 150px;
  }

  // Gauge
  .container-gauge{
    width:422px;
    height:240px;
    left: 50%;
    overflow: hidden;
    text-align: center;
    transform: translate(-50%, -50%);
  }
  .gauge-a{
    z-index: 1;
    position: absolute;
    background: $stevie-gradient-gauge-a;
    width: 350px;
    height: 175px;
    bottom: 0;
    left: 36px;
    border-radius:250px 250px 0 0 ;
  }
  .gauge-b{
    z-index: 2;
    position: absolute;
    background: $stevie-gradient-gauge-b;
    width: 350px;
    height: 175px;
    bottom: 0;
    left: 36px;
    border-radius:250px 250px 0 0 ;
  }
  .gauge-c{
    z-index: 3;
    position: absolute;
    background: $stevie-gradient-gauge-c;
    width: 350px;
    height: 175px;
    bottom: 0;
    left: 36px;
    border-radius:250px 250px 0 0 ;
  }
  .gauge-d{
    z-index: 3;
    position: absolute;
    background-color: $stevie-white;
    width: 150px;
    height: 75px;
    top: 165px;
    left: 36px;
    margin-left: 100px;
    margin-right: auto;
    border-radius:250px 250px 0 0 ;
  }
  .gauge-data{
    z-index: 4;
    color: $stevie-white;
    position: absolute;
    width: 422px;
    height: 240px;
    top: 70%;
    transition: all 1s ease-out;
  }
  .gauge-line {
    z-index: 4;
    position: absolute;
    width: 208px;
    top: 190px;
    transform-origin: left center;
    transform: rotate(-180deg);
  }

  // Landing page
  .landing-page-padding-left {
    padding-left: 40px !important;
  }
  .text-landing-stevie {
    font-size: 16px;
  }
  .center-position-absolute-right-left {
    right: 60px;
    transform: translateX(50%) translateY(-50%);
  }

  // Navbar
  .logo-size-vi {
    width: 60px;
  }
}
@media (min-width: 576px) {
  .font-size-title-form {
    font-size: 1.6rem;
  }
  .navbar-height-vi {
    height: 138px;
  }
  .img-height {
    margin-top: 0;
    height: 600px;
  }

  // Landing page
  .center-position-absolute-right-left {
    left: 60px;
    transform: translateX(-50%) translateY(-50%);
  }
}
@media (max-width: 768px) {
  // Backgrounds
  .bg-before-landing-stevie:before {
    left: 15px;
    right: 15px;
  }

  // Cards
  .text-company-size {
    font-size: 12px;
  }
  .card-form-vi {
    height: auto;
  }

  // Dashboard
  .dashboard-margin {
    margin-left: $dashboard-margin-left-sm;
    margin-right: $dashboard-margin-right-sm !important;
  }
  .g-sidenav-hidden .dashboard-margin {
    margin-left: $dashboard-margin-left-sm;
    margin-right: $dashboard-margin-right-sm;
  }

  //Navbar
  .padding-navbar {
    padding-left: 30px;
    padding-right: 30px;
  }

  // Sidebar
  .g-sidenav-pinned .sidenav {
    min-width: calc(100% - 60px) !important;
  }
  .stevie-sidebar {
    margin-left: 22px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  // Card
  .font-size-title-form {
    font-size: 2rem;
  }
  .card-form-vi {
    height: auto;
  }

  // Sidebar
  .g-sidenav-pinned .sidenav {
    min-width: calc(100% - 198px) !important;
  }
  .g-sidenav-pinned .dashboard-margin {
    margin-left: 99px;
  }

  // Landing page
  .title-size-landing-stevie {
    font-size: 30px;
  }
  .font-size-landing-stevie {
    font-size: 16px;
  }
  .mt-landing-stevie {
    margin-top: -200px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .font-size-title-form {
    font-size: 2.5rem;
  }

  // Landing page
  .title-size-landing-stevie {
    font-size: 50px;
  }
  .font-size-landing-stevie {
    font-size: 18px;
  }
  .mt-landing-stevie {
    margin-top: -260px;
  }

  // Profile data
  .img-profile-data {
    width: 152px;
    height: 152px;
    border-radius: 100px;
  }
  .online-shape-profile {
    margin: 128px 5px 0 100px;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .font-size-title-form {
    font-size: 3rem;
  }

  // Landing page
  .title-size-landing-stevie {
    font-size: 46px;
  }
  .font-size-landing-stevie {
    font-size: 18px;
  }

  // Profile data
  .img-profile-data {
    width: 152px;
    height: 152px;
    border-radius: 100px;
  }
  .online-shape-profile {
    margin: 128px 5px 0 100px;
  }
}

@media (min-width: 1441px) {
  .font-size-title-form {
    font-size: 3rem;
  }

  // Landing page
  .title-size-landing-stevie {
    font-size: 60px;
  }
  .font-size-landing-stevie {
    font-size: 18px;
  }
  .mt-landing-stevie {
    margin-top: -300px;
  }

  // Profile data
  .img-profile-data {
    width: 152px;
    height: 152px;
    border-radius: 100px;
  }
  .online-shape-profile {
    margin: 128px 5px 0 100px;
  }
}
//Select Element UI
.el-select{
  .el-input .el-select__caret{
    color: #012454!important;
    font-size: 18px!important;
    font-weight: 700!important;
    line-height: 34px!important;
  }
  .el-input__inner{
    height: 34px!important;
    line-height: 34px!important;
    padding: 0px 10px;
    border: 1px solid #c6dcf5;
    border-radius: 5px!important;
    font-family: Montserrat;
    font-size: 13px;
    color: #012454;
    text-decoration: none solid rgb(1, 36, 84);
    font-weight: 500;
    box-shadow: 0px 1px 5px rgba(3,115,168,0.2);
    &:focus{
      border-color: #86d3f7;
    }
  }
  .is-focus .el-input__inner{
    border-color: #86d3f7!important;
  }
}
.el-select__popper{
  border: 1px solid #86d3f7 !important;
  border-radius: 5px;
  background-color: #ffffff;
  background-size: cover;
  &.el-popper{
    max-width: none!important;
  }
  .el-popper__arrow{
    display: none;
  }
  .el-select-dropdown__item{
    height: 34px!important;
    margin: 0 2px;
    padding: 0px 24px;
    background-size: cover;
    font-family: Montserrat;
    font-size: 13px;
    color: #16181b!important;
    text-decoration: none solid rgb(22, 24, 27);
    font-weight: 500;

    &.is-disabled{
      font-weight: 500;
      color: #C0C4CC!important;
      cursor: not-allowed!important;
    }
    &.selected{
      font-weight: 600;
      color: #012454!important;
    }
    &:hover{
      background-color: #c6dcf5;
    }
  }
}
.el-select-dropdown.select-no-scroll{

  .el-select-dropdown__wrap{
    max-height: none;
  }
}
.footer-html{
  strong{
    font-weight: 600!important;
  }
}

//Color picker
.el-color-picker {

  .el-color-picker__trigger{
    width: 68px!important;
    height: 34px;
    padding: 6px;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 5px rgba(3,115,168,0.2);
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #c6dcf5;

    ::before{
      content: '';
      position: absolute;
      width: 14px;
      height: 9px;
      left: 38px;
      top: 6px;
      background: url('/img/icons/stevie/arrow-down.svg') no-repeat left;
    }
    .el-color-picker__color{
      border: 1px solid rgba(3,115,168,0.2);
      width: 28px;
      height: 20px;
      padding: 0!important;
    }
    .el-color-picker__icon{
      display: none;
    }
  }
  &.is-disabled {
    .el-color-picker__trigger {
        background-color: #e9ecef;
    }
    .el-color-picker__mask{
      display: contents;
    }

  }
}

// File uploader
.disabled .el-upload{
  opacity: 0.5;
}
.el-upload {
  width: 100%;
  height: 100%!important;
  .el-upload-list__item-actions{
    background-color: rgba(0,0,0, 0.5);
    opacity: 0;
    position:absolute;
    width: 100%;
    height: 100%;
    &:hover{
      opacity: 1;
      display: block;
    }
    .el-upload-list__item-delete{
      display: block;
      padding-top: 10px;
      color: #ffffff;
    }
  }
}
.el-upload-dragger {
  width: 100%!important;
  margin: 0!important;
  border-radius: 5px;
  border: 2px dashed #86d3f7!important;
  background: transparent!important;
  height: 100%!important;

  .el-upload__text {
    height: 100%;
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #012454;
    font-weight: 500;
  }
  .el-upload__image{
    height: 100%;
    display: flex;

    .previewImg,
    .preview, .avatar {
      height: 100%!important;
      width: 100%;
      object-fit: cover;
    }
    .text-hover{
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.6);
      color: #8C8C93;
      width: 100%;
      height: 100%;
      font-weight: 500;
      padding: 15px;
    }
    &:hover .text-hover{
      display: flex;
    }
  }
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.object-fit-cover {
  object-fit: cover;
}
.text-required-error {
  position: absolute;
  display: block;
  top:3.5rem;
  font-size: 0.7rem;
}

.text-secondary-optional {
  font-size: 1rem;
  color: #aaa;
  font-weight: 300;
}

.text-red{
  color: $stevie-radical-red;
}

.disabled-btn {
  cursor: default!important;
}

.text-required-error {
  position: absolute;
  display: block;
  top:3.5rem;
  font-size: 0.7rem;
}


// Fonts
.bold {
  font-weight: bold;
}

.capitalize {
  text-transform: capitalize;
}

.fw-600 {
  font-weight: 600;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}
.fw-300 {
  font-weight: 300;
}
