
.custom-badge{
  color: black;
  padding: 0.2rem 0.3rem;
  text-transform: capitalize;
  &.badge-success{
    background-color: #BCFD91!important;
  }
  &.badge-danger{
    background-color: #FEC6CF!important;
  }
  &.badge-max{
    font-weight: 800;
    background-color: white!important;
    border: 1px solid #013768;
  }
}
.title-plugin{
  font-size: 16px;
}
.info-card{
  min-width: max-content;
}

.bar-holder {
  width: 100%;
  height: 3px;
  background-color: rgb(231, 231, 231);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin: 0 auto;
}

.bar-5 {
  height: 5px;
  color: #fff;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  text-align: center;
  font-weight: 400;
  background-image: linear-gradient(100deg, rgb(255, 255, 255), rgb(56, 117, 160));
  filter: blur(1px);
}

.icons-distribution {
  height: 20px;
}
