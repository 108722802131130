
.dropdown-item{
  height: 33px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover{
    color: #16181b!important;
    background-color: #f6f9fc!important;
  }
}
.min-table{
  min-height: 450px;
}

.template-card{
  margin: 0 10px 50px 10px;
  width: 258px;
  height: 377px;
  border-radius: 20px;
  box-shadow: 0px 15px 35px rgba(3,115,168,0.1), 0px 20px 10px rgba(3,115,168,0.1);
  p{
    margin: 0;
  }
  &__image{
    width: 100%;
    height: 177px;
    min-height: 177px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    object-fit: cover;
  }
  &__header{
    height: 33px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    text-transform: uppercase;
    color: #ffffff;
    p{
      font-size: 16px;
      font-weight: 500;
    }
  }
  &__body{
    padding: 13px;
    padding-bottom: 29px;
    height: 167px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p{
      font-size: 13px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;  
      overflow: hidden;
      line-height: 1em;  
    }
    .template-button{
      margin-top: auto;
    }


  }
  &:nth-child(1n) .template-card__header{
    background-color: #012454;
  }
  &:nth-child(2n) .template-card__header{
    background-color: #0373a8;
  }
  &:nth-child(3n) .template-card__header{
    background-color: #8dfd36;
  }
  .template-height{
    height: 377px;
  }
  
  .btn-group{
    .btn-action{
      height: 38px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

}
