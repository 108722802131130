
.custom-badge{
  color: black;
  padding: 0.2rem 0.3rem;
  text-transform: capitalize;
  &.badge-success{
    background-color: #BCFD91!important;
  }
  &.badge-danger{
    background-color: #FEC6CF!important;
  }
  &.margin-icon{
    margin-right: 19px;
  }
  &.badge-max{
    font-weight: 800;
    background-color: white!important;
    border: 1px solid #013768;
  }
}
.title-plugin{
  font-size: 16px;
}
.info-card{
  min-width: max-content;
}

