
  :deep()label{
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
    color: #525f7f;
  }
  :deep()input[id="email"]{
    appearance: none!important;
    padding-left: 33px!important;
    background: url('/img/icons/stevie/envelope-solid.svg') no-repeat left;
    background-size: 13px;
    background-position: 10px 10px;
  }
  :deep()input[type="password"]{
    appearance: none!important;
    padding-left: 33px!important;
    background: url('/img/icons/stevie/unlock-keyhole-solid.svg') no-repeat left;
    background-size: 13px;
    background-position: 10px 10px;
  }
  .error-message-top {
    top: 2.2rem;
  }
